import "./App.css";
import Contactus from "./components/contactus";
import { Routes, Route } from "react-router-dom";
import Faqs from "./components/faqs";
import Main from "./components/main";
import Header from "./components/header";
import Footer from "./components/footer";

import Insidethefirm from "./components/blogs/insidethefirm";
import Floridalawupdates from "./components/blogs/floridalawupdates";
function App() {
  return (
    <>
    {/* header */}
  
    <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/insidethefirm" element={<Insidethefirm/>}/>
        <Route path="/Floridalawupdates" element={<Floridalawupdates />} />
      </Routes>
       {/* <Extra/> */}
      <Footer/>
    </>
  );
}

export default App;
