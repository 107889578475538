import { MapPinIcon } from "@heroicons/react/24/solid";
import React from "react";
import Mapimg from "../assets/map.jpg";
const Fourthsection = () => {
  const [city1, setCity1] = React.useState(false);
  const [city2, setCity2] = React.useState(false);
  const [city3, setCity3] = React.useState(false);
  const [city4, setCity4] = React.useState(false);
  const [city5, setCity5] = React.useState(false);
  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap max-w-[1400px] mx-auto   pb-20 mt-32">
        <div className="xl:pl-10 md:pl-20 pl-5">
          <p className="text-[#d5b661] text-lg text-[18px] whitespace-pre -tracking-tighter">
                BASED IN LAKE MARY
          </p>
          <h1 className="font-bold text-5xl py-3 text-[#2d2424]">
            Satisfied Clients Throughout <br /> The State Of Florida
          </h1>

          <div className="pt-7 text-[#6e6e6e] ">
            <p>
              Law Office Of Mike Toole is an experienced law firm based in Lake
              Mary, Florida.
              <br />
              We’re a small team of legal professionals who serve each client
              with dedication and respect to ensure their <br />
              utmost satisfaction.{" "}
            </p>
            <p className="pt-5">
              To keep up with client expectations, we also offer remote
              consultation services in addition to one- <br />
              on-one meetings. Hence, we have a state-wide reputation for being
              one of the most reliable <br />
              providers of legal guidance in Florida.{" "}
            </p>
            <p className="pt-5">
              From personal injury to estate planning, family matters,
              immigration, and much more, The Law <br />
              Office Of Mike Toole is the way to go. Attorney Michael Toole can
              help you take the most <br />
              informed steps toward the fruitful resolution of your case.
            </p>
          </div>
        </div>
        {/* Second Secondsection */}
        <div className="max-w-[600px]  mx-auto  relative ">
          <img src={Mapimg} alt="mapIcon" className="mt-10 lg:min-w-[550px] max-w-[350px] sm:max-w-[500px] mx-auto sm:w-full " />
          <div className="absolute top-24 left-16 ">
          <MapPinIcon
          onClick={()=> setCity1(!city1)}
          className="w-6 h-6 text-[#d5b661] cursor-pointer "/>
           <span className={`${city1 ? "block text-[#6e6e6e] " : "hidden"}`}>California</span>
          </div>
         <div className=" absolute top-52 left-28 ">
         <MapPinIcon onClick={()=>setCity2(!city2)} className=" w-6 h-6 text-[#d5b661] cursor-pointer"/>
         <span className={`${city2 ? "block text-[#6e6e6e]" : "hidden"}`}> Les Vegas </span>   
         </div>
         <div className="absolute top-24 left-[320px] ">
          <MapPinIcon onClick={()=>{setCity3(!city3)}} className="w-6 h-6 text-[#d5b661] cursor-pointer"/>
       
         <span className={`${city3? "block text-[#6e6e6e]" : "hidden"}`}> Amsterdam </span>
         
          </div>
          <div className="absolute sm:top-44 top-36 sm:left-64 left-44">
          <MapPinIcon onClick={()=>{setCity4(!city4)}} className="w-6 h-6 text-[#d5b661] cursor-pointer "/>
         <span className={`${city4 ? "bloc text-[#6e6e6e]" : "hidden"}`}>Los Angles</span> 
          </div>
         <div className=" absolute sm:top-60 top-52 sm:left-[460px] right-[25px] ">
         <MapPinIcon onClick={()=>{setCity5(!city5)}} className="w-6 h-6 text-[#d5b661] cursor-pointer"/>
         <span className={`${city5 ? "block text-[#6e6e6e]" : "hidden"}`} > Meulbeurn </span> 
         </div>
          

        </div>
      </div>
    </>
  );
};

export default Fourthsection;
