import { CheckCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import Header from "../header";
import { TypeAnimation } from "react-type-animation";
const FirstSection = () => {
  return (
    <>
      <div>
        <div className="bg_img bg-no-repeat bg-cover w-[100%]  h-[800px] bg-center   ">
          <Header />
          <div className=" xl:max-w-7xl w-[90%]   text-white mx-auto mt-[70px] ">
            <div>
              <main className=" flex items-center">
                <p className="  lg:text-[1rem] xl:text-[1rem]  md:text-[1rem] sm:text-[1rem] text-white text-[1rem]">
                  WE DO
                </p>
                <TypeAnimation
                  sequence={[
                    "POWER OF ATTORNEY",
                    2000,
                    "PERSONAL INJURY",
                    2000,
                    "FAMILY LAW",
                    2000,
                    "PROBATE",
                    2000,
                    "WILLS",
                    2000,
                    "GUARDIANSHIP",
                    2000,
                    "IMMIGRATION",
                    2000,
                  ]}
                  speed={18} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity}
                  className="  ml-3 lg:text-[1rem] xl:text-[1rem]  md:text-[1rem] sm:text-[1rem] text-[#D5B661] tracking-wider pt-0 font-medium " // Repeat this Animation Sequence infinitely
                />
              </main>
            </div>
            <h1 className="text-white sm:text-[40px] md:text-[60px]  font-bold">
              Professional Legal & <br /> Lawyer Services
            </h1>
            <p className="text-white my-6 ">
              Here to help and guide you during life’s stressful moments.
            </p>

            <div className="leading-8">
              <div className="flex items-center ">
                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />
                <p className="ml-3"> Personalized legal experience</p>
              </div>

              <div className="flex items-center">
                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />
                <p className="ml-3">Have Professional Team</p>
              </div>

              <div className="flex items-center">
                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />
                <p className="ml-3">Same Day Response Time</p>
              </div>

              <div className="mt-2">
              <a href="#Services">


                <button className="bg-[#d5b661] w-40 tracking-wider font-bold rounded-lg text-white mt-5  py-2 text-base hover:bg-white hover:text-black hover:-translate-y-2">
                  Our Services
                </button>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstSection;
