import { ChevronRightIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import React from "react";
import logo from "./assets/logo.png";

import { FaFacebook, FaPhoneSquareAlt, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="px-4 pt-20 bg-[#2d2424]  dark:bg-gray-900  text-white border-t-[15px] border-[#d5b661]  ">
        <div className="xl:max-w-[70%] lg:max-w-[100%] md:max-w-[50] mx-auto">
          <div className="md:flex md:justify-between ">
            <div className="mb-6 md:mb-4  ">
              <p className="flex items-center md:mx-auto ">
                <Link to="/">
                  <img src={logo} className="w-[250px]" alt="LawOffice" />
                </Link>

                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
              </p>
              <p className="text-white-600 ">
                We Do Personal Injury, Family Law, Probate, Wills, Guardianship,
              </p>
              <p className="text-white-600">
                Immigration, Power of Attorney, Enhanced Life Estate Deeds.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3   ">
              <div className="min-[300px]">
                <h2 className="mb-6  font-semibold text-white uppercase dark:text-white text-2xl">
                  Quick Links
                </h2>
                <ul className="text-gray-600 dark:text-gray-400 mt-3">
                  <li className="mb-2">
                    <a
                      href="#"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Home
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="/#aboutUs"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      About Us
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="/#Services"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Our Services
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="/faqs"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      FAQs
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="/#blog"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Blogs
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="/contact"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="min-[300px]">
                <h2 className="mb-6  font-semibold text-white uppercase dark:text-white text-2xl">
                  Our Services
                </h2>
                <ul className="text-gray-600 dark:text-gray-400">
                  <li className="mb-4">
                    <a
                      href="#"
                      className="hover:underline  text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Personal Injury
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Family Law
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#"
                      className="hover:underline  text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Probate
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Wills
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#"
                      className="hover:underline  text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Gradianship
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Immigration
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="hover:underline text-white flex hover:text-[#d5b661]"
                    >
                      <ChevronRightIcon className="h-6 w-5 text-yellow-300" />
                      Power of Attorney And <br /> Enhanced life state
                    </a>
                  </li>
                </ul>
              </div>
              <div className="min-[300px]">
                <h2 className="mb-6 text-2xl font-semibold text-white-900 uppercase dark:text-white">
                  Contact Us
                </h2>
                <ul className="text-gray-600 dark:text-gray-400">
                  <li className="mb-4">
                    <a
                      href="https://www.google.com/maps/place/1525+International+Pkwy,+Lake+Mary,+FL+32746,+USA/@28.784893,-81.356714,16z/data=!4m6!3m5!1s0x88e70d469f4579df:0x2291b0544cfb73c0!8m2!3d28.7848932!4d-81.3567137!16s%2Fg%2F11bw3yzrqz?hl=en&entry=ttu"
                      target="_blank"
                      className="hover:underline text-white hover:text-[#d5b661]"
                    >
                      1525 International Parkway, Suite <br />
                      3011, Lake Mary, Florida 32746
                    </a>
                  </li>
                  <li className="mb-4 flex text-white">
                    <FaPhoneSquareAlt className="mr-3 h-5 w-5 text-[#D5B661]" />

                    <a
                      href="tel:(407)205-8106"
                      className="hover:underline text-white hover:text-[#d5b661]"
                    >
                      (407)205-8106
                    </a>
                  </li>
                  <li className="mb-4 flex text-white">
                    <EnvelopeIcon className="mr-3 h-5 w-5 text-[#D5B661]" />
                    <a
                      href="mailto:info@mtjrlaw.com"
                      className="hover:underline text-white hover:text-[#d5b661]"
                    >
                      info@mtjrlaw.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-solid border-[#524e53] border-y  sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between text-base">
            <div className="text-sm  text-white sm:text-left">
              © 2023 <a href="#" className="hover:underline"></a>. All Rights
              Reserved.
            </div>

            <div className="flex py-3">
              <div className="flex ">
                <span className="px-1"> Created By </span>{" "}
                <a
                  href="https://internativelabs.com/"
                  target="_blank"
                  className="text-[#f5ce64]"
                >
                 
                  Internative Labs
                </a>
              </div>
            </div>

            <div className="flex py-3">
              <div className="flex ">
                <div className="mr-0">
                  <a href="https://www.facebook.com/mtjrlaw" target="_blank">
                    <FaFacebook className="w-10 h-6 text-white-600 fill-current" />
                  </a>
                </div>
                <div className="mr-10 ml-0">
                  <a
                    href="https://www.youtube.com/channel/UC20BckTLejdn0ZgtVPCMcfQ"
                    target="_blank"
                  >
                    <FaYoutube className=" w-10 h-6 text-white-600 fill-current" />
                  </a>
                </div>
              </div>
            </div>
            {/* 
            <div className="flex">
              <p className="border-r-2 border-white pr-3 hover:text-[#d5b661] ">
              
                <a href="#">Privacy Policy</a>
              </p>
              <p className="border-r-2 border-white px-3 hover:text-[#d5b661]">
              
                <a href="#">Disclaimer</a>
              </p>
              <p className="right-3">
              
                <a href="#" className="right-2 pl-3 hover:text-[#d5b661]">
                  Term oF Service
                </a>
              </p>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
