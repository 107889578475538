import React from "react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import logo from "./assets/logo.png";

import { FaPhoneSquare } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="  xl:max-w-[1440px] w-[95%]   text-white mx-auto">
        <div className="lg:flex justify-between hidden pt-2 pb-4 ">
          <div className="flex">
            <div className="flex">
              <MapPinIcon className="h-6 w-6 text-[#D5B661]" />

              <a href="https://www.google.com/maps/place/1525+International+Pkwy,+Lake+Mary,+FL+32746,+USA/@28.784893,-81.356714,16z/data=!4m6!3m5!1s0x88e70d469f4579df:0x2291b0544cfb73c0!8m2!3d28.7848932!4d-81.3567137!16s%2Fg%2F11bw3yzrqz?hl=en&entry=ttu" 
              target="_blank"
              className="uppercase font-[13]">
                1525 INTERNATIONAL PARKWAY, SUITE 3011, LAKE MARY, FLORIDA 32746
              </a>
            </div>
            <div className="flex  font-[13] ml-5 ">
              <FaPhoneSquare className="h-6 w-6 text-[#D5B661]" />
              <a href="tel:(407) 205-8106" className="left-4 ml-3">(407) 205-8106</a>
            </div>
          </div>
          <div className="flex  justify-between ">
            <div className="mr-5">
              <a href="https://www.facebook.com/mtjrlaw" target="_blank">
              <svg
                className="w-5 h-5 text-white-600 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
              </svg>
              </a>
            
            </div>
            <div className="mr-10">
              <a href="https://www.youtube.com/channel/UC20BckTLejdn0ZgtVPCMcfQ" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
              </svg>
              </a>
             
            </div>
          </div>
        </div>

        {/* container  */}
        <div className="border-solid border-[#524e53] border-y flex sm:flex-row flex-col items-center justify-between">
          <div className="max-w-[180px] lg:max-w-[220px] " >
          <Link to="/">
          <img src={logo} alt="logo" className="w-full" />
          </Link>
           
          
          </div>
          <div className=" m-7 flex mr-[15%]  ">
            <ul className="flex justify-evenly">
              <Link to="/">
              <li
              className={`sm:p-4 py-3 px-2 sm:text-lg md:mx-0 mx-[5px] text-sm md:p-10 text-white cursor-pointer ${
                pathname === "/" ? "bg-[#D5B661]" : ""
              } `}
            >
              Home
            </li>
              
              </Link>
              <Link to="/faqs">
              <li
              className={`sm:p-4 py-3 px-2 sm:text-lg md:mx-0 mx-[5px] text-sm md:p-10 text-white cursor-pointer ${
                pathname === "/faqs" ? "bg-[#D5B661]" : ""
              } `}
            >
            FAQS
            </li>
              
              </Link>
              <Link to="/contact">
              <li
              className={`sm:p-4 py-3 px-2 sm:text-lg md:mx-0 mx-[5px] text-sm md:p-10 text-white cursor-pointer ${
                pathname === "/contact" ? "bg-[#D5B661]" : ""
              } `}
            >
            Contact Us
            </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
