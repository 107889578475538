import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import Header from "./header";

const Faqs = () => {
  const [first, setFirst] = React.useState(false);
  const [second, seteSecond] = React.useState(false);
  const [third, setThird] = React.useState(false);
  const [fourth, setFourth] = React.useState(false);
  const [fifth, setFifth] = React.useState(false);

  return (
    <div>
      <div className="bg_img bg-no-repeat bg-cover w-[100%] h-[600px] bg-center   ">
        <Header />
        <div className=" xl:max-w-7xl w-[90%]   text-white mx-auto mt-[70px] ">
          <h1 className="text-white text-5xl font-bold mb-[20px] md:text-7xl">
            FAQs
          </h1>
          <p className="text-white  lg:max-w-[900px]">
            Below are the answers to common questions regarding a personal
            injury negligence claim. If you have any questions that you would
            like answered, please reach out to our office on the Contacts Us
            page. We will follow up with you directly and your question may be
            added to our FAQs below.
          </p>
        </div>
      </div>
      {/* Faqs Waka Sections  */}
      <div className=" max-w-[90%] md:max-w-[75%] xl:max-w-[50%] my-20 mx-auto">
        <div
          onClick={() => setFirst(!first)}
          className=" flex items-center py-3 cursor-pointer border border-[#d4d4d4] pl-5 pr-5"
        >
          <div className="pr-3">
            {" "}
            {first ? (
              <FaMinus className="text-[#6e6e6e]" />
            ) : (
              <FaPlus className="text-[#6e6e6e]" />
            )}{" "}
          </div>
          <p className="text-[#D5B661] font-bold">
            {" "}
            What is negligence and how to prove it in court?{" "}
          </p>
        </div>
        <div className={first ? "block border border-[#d4d4d4]" : "hidden"}>
          <p className="pt-4 py-7 px-3 text-[#6e6e6e]">
            Negligence is when a person or entity falls below the reasonable
            person (or entity) standard, i.e. – what would a reasonable person
            do in a similar situation. To prove negligence, one must prove four
            elements: A duty of care owed to the plaintiff (reasonable person
            standard) The defendant breached this duty of care The plaintiff’s
            injuries are caused by the defendant’s breach, and Damages exist as
            a result of the breach The defendant can attack any and all of these
            elements to establish that no negligence occurred, e.g. –
            comparative fault on behalf of the plaintiff themselves or from a
            third-party.
          </p>
          <ul className="list-disc px-10 text-[#6e6e6e]">
            <li>
              A duty of care owed to the plaintiff (reasonable person standard)
            </li>
            <li>The defendant breached this duty of care</li>
            <li>
              The plaintiff’s injuries are caused by the defendant’s breach, and
            </li>
            <li>Damages exist as a result of the breach</li>
          </ul>
          <p className="py-7 px-3 text-[#6e6e6e]">
            The defendant can attack any and all of these elements to establish
            that no <br />
            negligence occurred, e.g. – comparative fault on behalf of the
            plaintiff themselves or <br /> from a third-party.
          </p>
        </div>
        {/* Second Question */}
        <div
          onClick={() => seteSecond(!second)}
          className="flex items-center cursor-pointer py-3 border border-[#d4d4d4] pl-5 pr-5"
        >
          <div className="pr-3">
            {" "}
            {second ? (
              <FaMinus className="text-[#6e6e6e]" />
            ) : (
              <FaPlus className="text-[#6e6e6e]" />
            )}{" "}
          </div>
          <p className="text-[#D5B661] font-bold">
            {" "}
            What is the process for handling a negligence case?{" "}
          </p>
        </div>
        <div className={second ? "block border border-[#d4d4d4]" : "hidden"}>
          <div className="text-[#6e6e6e] px-5 py-5">
            <h2 className="font-bold">AT THE SCENE:</h2>
            <p className="pb-3  ">
              If possible, have you or someone take photographs of the scene and{" "}
              <br />
              damages, both close-ups and distance photographs are needed to
              establish to the <br />
              jury how an incident occurred and what the injuries looked like.
              Note any witnesses, <br />
              collect contact information, and locate any possible video
              surveillance that may have <br />
              captured the incident. If you feel injured in any way as a result
              of the incident, seek <br />
              medical attention. Contact a personal injury attorney.
            </p>

            <h2 className="font-bold">FOLLOWING THE INCIDENT:</h2>
            <p className="pb-3 ">
              {" "}
              You or your attorney can send out letters to gather and preserve{" "}
              <br />
              evidence and find all possible insurance coverage for the injuries
              caused by the <br />
              incident. Keep a post-incident journal of your daily activities
              and note when <br />
              you experienced pain from the injuries that were a result of the
              incident. Continue to get <br />
              medical treatment if needed. Typically, after medical records and
              bills are gathered, <br />
              a demand for compensation is made to any insurance company and/or
              the <br />
              defendant who should be providing compensation. If you and the
              insurance <br />
              company and/or defendant cannot come to an agreement on
              compensation, then <br />
              you may file a lawsuit to recover for all damages suffered by you
              as a result of the <br />
              incident.
            </p>
            <h2 className="font-bold">AFTER FILING SUIT:</h2>
            <p className="pb-3 ">
              {" "}
              You or your attorney will continue with the litigation process,
              which <br />
              includes but is not limited to, filing a well-plead complaint,
              service of process, <br />
              discovery, motions, evidence gathering, depositions, mediations,
              expert witnesses, <br />
              and going to trial all within strict deadlines imposed by the
              court. Attorney Michael <br />
              Toole is well experienced in all phases of a negligence claim and
              can provide well <br />
              informed insight about how the process works.
            </p>
          </div>
        </div>

        {/* ThirdQuestion */}
        <div
          onClick={() => setThird(!third)}
          className="flex items-center py-3 cursor-pointer border border-[#d4d4d4] pl-5 pr-5"
        >
          <div className="pr-3">
            {" "}
            {third ? (
              <FaMinus className="text-[#6e6e6e]" />
            ) : (
              <FaPlus className="text-[#6e6e6e]" />
            )}{" "}
          </div>
          <p className="text-[#D5B661] font-bold">
            {" "}
            What is the statute of limitation on a negligence claim?{" "}
          </p>
        </div>
        <div className={third ? "block border border-[#d4d4d4]" : "hidden"}>
          <p className="text-[#6e6e6e] px-5 py-2">
            The statute of limitations means that a potential Plaintiff cannot
            file a lawsuit after a <br />
            certain amount of time. In Florida, the statute of limitations on a
            negligence claim is <br />
            four (4) years.
          </p>
        </div>

        {/* Four Question Question */}
        <div
          onClick={() => setFourth(!fourth)}
          className="flex items-center cursor-pointer py-3 border border-[#d4d4d4] pl-5 pr-5"
        >
          <div className="pr-3">
            {" "}
            {fourth ? (
              <FaMinus className="text-[#6e6e6e]" />
            ) : (
              <FaPlus className="text-[#6e6e6e]" />
            )}{" "}
          </div>
          <p className="text-[#D5B661] font-bold">
            What are the costs in a negligence claim?{" "}
          </p>
        </div>
        <div className={fourth ? "block border border-[#d4d4d4]" : "hidden"}>
          <p className="text-[#6e6e6e] px-5 py-2">
            Depending on which level of the court system you are filing, the
            filing fee is typically <br />
            between $350.00 and $450.00. This also includes a clerk fee for
            processing and <br />
            executing the summons. Other costs in a negligence case include, but
            are not limited <br />
            to, service of process fees, depositions, gathering of medical
            evidence, mediation, <br />
            trial preparations, and expert witness opinions. These costs
            typically need to be paid <br />
            up front at the time these services are received. Hiring an attorney
            can help cover <br />
            the up-front costs of these services.
          </p>
        </div>
        {/* fifth Section */}
        <div
          onClick={() => setFifth(!fifth)}
          className="flex items-center py-3 cursor-pointer border border-[#d4d4d4] pl-5 pr-5"
        >
          <div className="pr-3">
            {" "}
            {fifth ? (
              <FaMinus className="text-[#6e6e6e]" />
            ) : (
              <FaPlus className="text-[#6e6e6e]" />
            )}{" "}
          </div>
          <p className="text-[#D5B661] font-bold">
            {" "}
            Why should you hire an attorney for your negligence claim?{" "}
          </p>
        </div>
        <div className={fifth ? "block border border-[#d4d4d4]" : "hidden"}>
          <p className="text-[#6e6e6e] px-5 py-2">
            Attorneys can assist in the processes and paperwork that come with a
            negligence <br />
            claim, giving you peace of mind. They have direct access to
            insurance companies <br />
            and opposing lawyers to negotiate and fight on your behalf. Attorney
            Michael Toole <br />
            has extensive experience working against insurance companies to
            provide the best <br />
            possible outcome for his clients. Studies have shown that people who
            hire an <br />
            attorney walk away with more money in their pockets at the end of
            the day.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
