import moment from "moment/moment";
import React, { useState } from "react";

const Blogcoment = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [web, setWeb] = useState("");

  const [userName, setUserName] = useState("");
  const [userComment, setUserComment] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const SubmitFrom = (e) => {
    e.preventDefault();
    const showDate = new Date();

    setUserName(name);
    setUserComment(comment);
  };

  return (
    <>
      {userName && userComment && (
        <>
          <div>
            <h1 className="tracking-wider text-[35px] font-bold">Responses</h1>
          </div>
          <div className=" flex  justify-between pl-0 p-10 flex-wrap">
            <div className="flex items-center ">
              <img
                src={require("../assets/profile.png")}
                className="w-12 h-12 mr-2 rounded-full"
                alt=""
              />
              <div>
                <p className="text-[#6E6E6E]">
                  {" "}
                  <span className="font-bold text-[#d5b661]">
                    {userName}
                  </span>{" "}
                  says:{" "}
                </p>
                <p className="text-[#6E6E6E]">{userComment}</p>
                <p className="text-[#d5b661] text-sm">reply</p>
              </div>
            </div>
            <p className="text-[#d5b661] text-[13px]">
              {moment().format("MMMM Do YYYY h:mm a")}
            </p>
            <p className="italic text-[#6E6E6E]">
              Your comment is awaiting moderation.
            </p>
          </div>
        </>
      )}

      <div className="py-6">
        <div>
          <h1 className="text-5xl leading-6 font-bold tracking-wider py-5">
            Leave a Reply
          </h1>
          <p className="text-[#6E6E6E] py-5 text-[15px]">
            Your email address will not be published. Required fields are marked
            *
          </p>
        </div>
        <form>
          <div>
            <label className="block text-[15px] font-medium text-[#6E6E6E] ">
              Comment *
            </label>
            {/* <input type='text' className="" /> */}
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="border-[#6E6E6E] border w-full outline-none  pl-1"
              name=""
              id=""
              cols="30"
              rows="8"
            ></textarea>
            <label className="block text-[15px] font-medium text-[#6E6E6E] pt-5 pb-2">
              Name *
            </label>
            <input
            type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border-[#6E6E6E] border w-full outline-none py-3 px-4"
              name=""
              id=""
            />
            <label className="block text-[15px] font-medium text-[#6E6E6E] pt-5 pb-2">
              Email *
            </label>
            <input
             type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-[#6E6E6E] border w-full outline-none py-3 px-4"
              name=""
              id=""
            />
            <label className="block text-[15px] font-medium text-[#6E6E6E]  outline-none pt-5 pb-2">
              Website{" "}
            </label>
            <input
            
              value={web}
              onChange={(e) => setWeb(e.target.value)}
              className="border-[#6E6E6E] py-3 px-4 border w-full"
              name=""
              id=""
            />
            <div className="flex">
              <input type="checkbox" />
              <label className="block text-[15px] font-medium text-[#6E6E6E]  mx-2">
                Save my name, email, and website in this browser for the next
                time I comment.
              </label>
            </div>
            <button
              onClick={SubmitFrom}
              className="bg-[#d5b661] text-white py-5 px-10 mt-4 rounded text-base"
            >
              Post Comment
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Blogcoment;
