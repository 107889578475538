import React from 'react'

import Fifthsection from './homePage/fifthsection'
import FirstSection from './homePage/firstsection'
import Fourthsection from './homePage/fourthsection'
import Secondsection from './homePage/secondsection'
import Sixthsection from './homePage/sixthsection'
import Thirdsection from './homePage/thirdsection'



const Main = () => {
  return (
     <>
     
     {/* <Faqs /> */}
     {/* <Contactus/> */}
     <FirstSection/>
     <Secondsection/>
     <Thirdsection/>
     <Fourthsection/>
     <Fifthsection/>
     <Sixthsection/>

     </>
  )
}

export default Main