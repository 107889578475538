import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import Header from "../header";
import Blogcoment from "./blogcoment";
import { Link } from "react-router-dom";

const insidethefirm = () => {
  return (
    <div>
      <div className="bg_img bg-no-repeat bg-cover w-[100%] h-[800px]  bg-center   ">
        <Header />
      </div>
      {/* Blog Section */}
      <div className="xl:max-w-[1400px] w-[90%] mx-auto bg-white  mt-[-10%] ">
        {/* Icons div */}
        <div className="flex  justify-center pt-24 pb-10 text-center">
          <FontAwesomeIcon
            icon={solid("calendar")}
            className="w-5 h-5 text-[#D5B661]"
          />
          <p className="px-2 text-[#D5B661]">August 19, 2022</p>
          <FontAwesomeIcon
            icon={regular("clock")}
            className="w-5 h-5 text-[#6E6E6E] pl-3"
          />
          <p className="text-[#6E6E6E] px-2">10:39 pm</p>
        </div>

        <div className="text-center">
          <h1 className="font-sans text-5xl tracking-wide font-bold leading-6 py-10">
            Inside the Firm
          </h1>

          <hr className="w-72 mx-auto  bg-[#D5B661] h-3 my-6" />
        </div>
        {/* Paragraph content */}
        <div className="mx-auto max-w-[95%] text-[#6E6E6E] text-[16px]  ">
          <p className="py-4">
            Attorney Michael Toole Jr. attended the University at Buffalo
            obtaining a Bachelor’s degree in Legal Studies. While attending, he
            was employed with the Federal District Court for the Western
            District of New York gaining first-hand knowledge of the daily
            processes in the legal system and learning how high-profile cases
            are presented at trial.
          </p>

          <p className="py-4">
            He attended law school at Barry University School of Law in Orlando,
            Florida and quickly earned a spot on the competitive Barry Trial
            Team. With the goal of being trial ready when he left law school, he
            was invited and competed in over ten tournaments with countless
            hours of training in his preparations. Attorney Toole has direct
            training from some of the best trial attorneys in the State of
            Florida.
          </p>
          <p className="pt-2">
            Within his first year of opening his practice, Attorney Toole has
            assisted in earning his clients over one million dollars in
            compensation and continues assist his clients during all phases of
            their claims. His focus is the care of the client, making sure their
            worries are eased, and providing the personal services resulting in
            the best outcomes.
          </p>
        </div>

        {/* Prev Next Section */}
        <div className="py-5 mx-auto max-w-[95%]  ">
          <div className="w-[50%] border-r border-[#d4d4d4] flex">
            <FontAwesomeIcon
              icon={solid("chevron-left")}
              className="w-7 h-5 text-[#d4d4d4] py-3 "
            />
            <div className="text-[.8rem] tracking-wider text-[#D5B661] px-2">
              <p>
                <Link to="/Floridalawupdates">
                PREVIOUS <br />
                  Florida Law Updates
                </Link>
                  
              
              </p>
            </div>
          </div>
          <Blogcoment/>
        </div>

      {/* Comment Section */}
      
      </div>
    </div>
  );
};

export default insidethefirm;
