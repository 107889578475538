import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import businespicture from "../assets/business-lawyer.jpg";
import {  useNavigate } from "react-router-dom";

const Secondsection = () => {
   const navigate = useNavigate()
  return (
    <div id="aboutUs">
        <div className="lg:flex lg:items-center ">
        <div className="xl:max-w-[860px] md:block hidden lg:max-w-[550px] xl:mr-6 lg:mr-12 bg-cover brightness-50">
          <img className="w-full lg:h-[600px]" src={businespicture} alt="Business-img" />
        </div>

        <div className="lg:max-w-[700px] w-[90%]  lg:min-w-[500px] mx-auto ">
          <div className="">
            <h2 className="text-[#d5b661] mb-3 text-lg whitespace-pre mt-10">
              PROFESSIONAL LAWYER
            </h2>
            <h1 className="xl:text-5xl lg:text-4xl text-3xl font-bold">
              We Have A Dedicated Staff <br /> To Make Your Legal <br />
              Experience Stress Free.
            </h1>
          </div>

          <div className=" mt-10 border-l-8 border-[#d5b661] ">
            <p className="ml-5 text-base text-[#6E6E6E]">
              From the simplest disputes to the most complicated legal issues,
              The Law
              <br />
              Office Of Mike Toole is here to help you through it all. Attorney Toole is proficient <br />
              in helping clients achieve the best legal guidance
              across a wide <br />
              range of legal aspects. <br />
              Building trust within our community by providing quality legal
              services and <br />
              giving a new meaning to the phrase, “That’s my lawyer"
            </p>
            <p className="ml-5 font-bold mt-2">Michael Toole Jr., Esq</p>
          </div>

          <div className="mt-7">
            <div className="sm:flex">
              <div className="flex mt-2 lg:mt-0 sm:max-w-[320px] w-full">
                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />

                <p className="ml-2 text-base text-[#6E6E6E]">
                  We are a full-service law firm
                </p>
              </div>
              <div className="flex mt-2 lg:mt-0 sm:max-w-[320px] w-full">
                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />

                <p className="ml-2 text-base text-[#6E6E6E]">
                  Transparent Trustworthy Procedures
                </p>
              </div>
            </div>
            <div className="sm:flex mt-6">
            <div className="flex mt-2 lg:mt-0 sm:max-w-[320px] w-full">

                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />

                <p className="ml-2 text-base text-[#6E6E6E]">
                  We are a full-service law firm
                </p>
              </div>
              <div className="flex mt-2 lg:mt-0 sm:max-w-[320px] w-full">

                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />

                <p className="ml-2 text-base text-[#6E6E6E]">
                  Transparent Trustworthy Procedures
                </p>
              </div>
            </div>

            {/* <div className="flex mt-5">
              <div className="flex max-w-[50%] sm:mr-10">
                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />

                <p className="ml-2 text-base text-[#6E6E6E]">
                  Various Areas Of Practice Experts
                </p>
              </div>
              <div className="flex max-w-[50%] border">
                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />

                <p className="ml-2 text-base text-[#6E6E6E]">
                  Quick Responses For Every Client
                </p>
              </div>
            </div> */}

            <div className="flex mt-5">
            <div className="flex lg:mt-0  w-full">

                <CheckCircleIcon className="w-6 h-6  rounded-[50%] text-[#D5B661]" />

                <p className="ml-2 text-base text-[#6E6E6E]">
                  Large Network to Get the Answers You <br />
                  Need
                </p>
              </div>
            </div>
          </div>
          
          <button className="bg-[#d5b661] w-40 tracking-wider border rounded-lg text-white mt-5  py-2 text-base hover:bg-[#2d2424] hover:-translate-y-2"
          onClick={()=>navigate("/insidethefirm")}
          >
            Our Story
          </button>
        </div>

        </div>
        <div className="flex mt-14 flex-wrap justify-around items-center">

        <div className="w-full mt-12">
          <div>
            <div className="flex items-center justify-center my-6">
              <p className="text-[#d5b661] whitespace-pre"> OUR PROCCES</p>
            </div>
            <div className="flex items-center justify-center my-5">
              <h1 className="text-5xl font-bold">How We Work</h1>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-[#6E6E6E] text-sm">
                Your Personalized Legal Experience
              </p>
            </div>
          </div>
        </div>

        <div className="w-full xl:flex md:max-w-[80%] max-w-[90%] flex-wrap justify-around xl:max-w-7xl mx-auto mt-12 items-center mb-14">
          <div className="border   xl:max-w-[380px] hover:shadow-lg   py-7  px-7 hover:-translate-y-2">
            <h1 className="font-bold text-lg p-3">Connect How You Want</h1>
            <p>- We’re Available 24/7</p>
            <p>- Reach Us Via Call, Text, or Email</p>
            <p>- Book A Consultation Session</p>
          </div>

          <div className="xl:flex xl:max-w-[450px]  items-center flex-col md:flex-row">
            <div className="xl:mr-6 mx-auto  max-w-[80px] rotate-90 xl:rotate-0 xl:mx-6 my-10">
              {/* <FaHandPointRight className="w-10 h-10 text-[#D5B661]" /> */}
              <img className="sm:w-24 w-12  sm:h-16 h-12" src={require("../assets/hand.png")} alt="left-hand" />
            </div>

            <div className=" border  xl:max-w-[380px] hover:shadow-lg w-full   py-7  px-7 hover:-translate-y-2">
              <h1 className=" font-bold text-lg p-3">
                Diligence in Handling Your Case</h1>
              <p>- Tell Us Your Story</p>
              <p>- Professional Legal Research Team</p>
              <p>- Let Us Give You Peace of Mind</p>
            </div>
          </div>
          <div className="xl:flex xl:max-w-[450px]  items-center flex-col md:flex-row">
            <div className="xl:mr-6 mx-auto  max-w-[80px] rotate-90 xl:rotate-0 xl:mx-6 my-10">
              {/* <FaHandPointRight className="w-10 h-10 text-[#D5B661]" /> */}
              <img className="sm:w-24 w-12  sm:h-16 h-12" src={require("../assets/hand.png")} alt="left-hand" />
            </div>

            <div className=" border  xl:max-w-[380px] hover:shadow-lg w-full   py-7  px-7 hover:-translate-y-2">
              <h1 className=" font-bold text-lg p-3">Finish on Your Terms</h1>
              <p>- Get Advice From One Of Our Expertise</p>
              <p>- Make Well Informed Decisions</p>
              <p>- Planning for All Outcomes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Secondsection;
