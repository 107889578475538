import React from "react";
import Header from "./header";
import { TypeAnimation } from "react-type-animation";
import Extra from "./homePage/extra";
const Contactus = () => {
  return (
    <>
      <div>
        <div className="bg_img bg-no-repeat bg-cover w-[100%] h-[600px] bg-center   ">
          <Header />
          <div className=" xl:max-w-[1400px] w-[90%]   text-white mx-auto mt-[70px] ">
            <h2 className="text-white text-1xl  mb-[20px]">
              <div className="flex items-center">
                <p className=" ml-3 lg:text-[1rem] xl:text-[1rem]  md:text-[1rem] sm:text-[1rem] text-white text-[1rem]">
                  WE DO
                </p>
                <TypeAnimation
                  sequence={[
                    "POWER OF ATTORNEY",
                    2000,
                    "PERSONAL INJURY",
                    2000,
                    "FAMILY LAW",
                    2000,
                    "PROBATE",
                    2000,
                    "WILLS",
                    2000,
                    "GUARDIANSHIP",
                    2000,
                    "IMMIGRATION",
                    2000,
                  ]}
                  speed={18} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity}
                  className="  ml-3 lg:text-[1rem] xl:text-[1rem]  md:text-[1rem] sm:text-[1rem] text-[#D5B661] tracking-wider pt-0 font-medium " // Repeat this Animation Sequence infinitely
                />
              </div>
            </h2>
            <h1 className="text-white text-[60px] font-bold">Contact Us</h1>
            <p className="text-white ">
              Get in touch with a Law Office Of Mike Toole representative today.
              We’re available 24/7 to help <br />
              you avail the best legal guidance in the state of Florida.
            </p>
          </div>
        </div>
        <Extra />
        {/* Map */}
        <div className="w-full ">
          <div className="google-map-code">
         
           <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6993.622242820235!2d-81.356714!3d28.784893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e70d469f4579df%3A0x2291b0544cfb73c0!2s1525%20International%20Pkwy%2C%20Lake%20Mary%2C%20FL%2032746%2C%20USA!5e0!3m2!1sen!2s!4v1706820521377!5m2!1sen!2s"
              width="100%"
              height="500"
              allowFullScreen
              loading="lazy"
              style={{ border: 0 }}
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3496.8109576471857!2d-81.35890238450251!3d28.784897883918475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e70d469f4579df%3A0x2291b0544cfb73c0!2s1525%20International%20Pkwy%2C%20Lake%20Mary%2C%20FL%2032746%2C%20USA!5e0!3m2!1sen!2s!4v1677281994139!5m2!1sen!2s"
              width="100%"
              height="500"
              allowFullScreen
              loading="lazy"
              style={{ border: 0 }}
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
