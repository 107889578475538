import React from "react";
import axios from 'axios';

const Extra = () => {

  const [data, setData] = React.useState({
    fName: "",
    lName: "",
    email: "",
    subject: "",
    message: "",
  })

  const submitForm = (e) => {

    e.preventDefault();
    axios({
      method: 'post',
      url: 'https://api.sendinblue.com/v3/smtp/email',
      headers: {
        'Content-Type': 'application/json',
        'api-key': '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'
      },
      data: {
        sender: {
          name: 'MTJR WEBSITE',
          email: 'info@mtjrlaw.com'
        },
        to: [
          {
            email: 'info@mtjrlaw.com'
          }
        ],
        replyTo: {
          email: 'info@mtjrlaw.com'
        },
        subject: data.subject,
        htmlContent: `
          <p>First Name: ${data.fName}</p>
          <p>Last Name: ${data.lName}</p>
          <p>Email: ${data.email}</p>
          <p>Subject: ${data.subject}</p>
          <p>Message: ${data.message}</p>
        `
      }
    })
      .then((response) => {
        // console.log(response);
        alert("Message Sent Successfully");

        // Handle success
      })
      .catch((error) => {
        // console.log(error);
        alert("Message Not Sent");
        // Handle error
      }).finally(() => {
        setData({
          fName: "",
          lName: "",
          email: "",
          subject: "",
          message: "",
        })
      })
  }
  

  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <>
      <div className=" bg-[#2d2424] py-16">
        <div className="xl:max-w-[1400px] w-[90%] flex items-center flex-wrap lg:flex-nowrap mx-auto">
          <div className="lg:mr-8 lg:w-[50%] w-full mb-10 ">
            {/* First Sub */}
            <div>
              <h4 className="text-[#d5b661]  text-xl">
                FILL OUT THE FORM AND
              </h4>
              <h1 className="text-white font-[700] my-5 leading-[55px] text-5xl">
                Law Office Of Mike Toole Will <br /> Contact You
              </h1>
              <p className="text-white ">
                Simply fill out the form on the left with your accurate
                information and someone from the Law <br />
                Office Of Mike Toole team will contact you shortly.
              </p>
            </div>
            <div>
              <div className="flex mt-6  items-center">
                <img className="w-[70px] h-16" src={require("../assets/contact-map.png")} alt="Map-img" />
                {/* <MapIcon className="text-[#d5b661] w-[50px]" /> */}
                <div className="pl-8">
                  <h4 className="text-white font-bold text-[25px]">
                    Office Address
                  </h4>
                  <a href="https://www.google.com/maps/place/1525+International+Pkwy,+Lake+Mary,+FL+32746,+USA/@28.784893,-81.356714,16z/data=!4m6!3m5!1s0x88e70d469f4579df:0x2291b0544cfb73c0!8m2!3d28.7848932!4d-81.3567137!16s%2Fg%2F11bw3yzrqz?hl=en&entry=ttu" 
                  target="_blank"
                  className="text-white text-[17px] ">
                    1525 International Parkway, Suite 3011, Lake Mary, Florida
                    32746
                  </a>
                </div>
              </div>

              <div className="flex mt-6">
                <img className="w-[70px] h-16" src={require("../assets/open-mail.png")} alt="Email-Icon" />
                {/* <EnvelopeIcon className="text-[#d5b661] max-w-[50px]" /> */}

                <div className="ml-8">
                  <h4 className="text-white font-bold text-[25px]">
                    Email Address
                  </h4>
                  <a href="mailto:info@mtjrlaw.com" className="text-white text-[17px]  ">
                    Info@mtjrlaw.com
                  </a>
                </div>
              </div>

              <div className="flex mt-6">
                {/* <PhoneIcon className="text-[#d5b661] max-w-[50px]" /> */}
                <img className="w-[70px] h-16" src={require("../assets/call-ring.png")} alt="Phone-Icon" />
                <div className="ml-8">
                  <h4 className="text-white font-bold text-[25px]">Call</h4>
                  <a href="tel:(407) 205-8106" className="text-white  text-[17px]">(407) 205-8106</a>
                </div>
              </div>
            </div>
          </div>
          {/* Secod Part */}
          <form className=" w-full lg:w-[50%] " onSubmit={submitForm}>
            <div className="w-full overflow-hidden ">
              <div className="flex">
                <div className=" mr-4 w-full lg:max-w-[50%]">
                  <p className="text-white mb-2">First Name</p>
                  <input className="w-full lg:w-full py-3 rounded-[4px]  px-3 border-gray-50 border-solid outline-none text-white  bg-[#4c4545]" type="text" onChange={changeHandler} value={data.fName} name="fName" placeholder=" First Name" />
                </div>
                <div className=" w-full lg:max-w-[50%]">
                  <p className="text-white mb-2">Last Name</p>

                  <input className=" w-full lg:w-full bg-[#4c4545]  px-3 py-3 rounded-[4px] border-gray-50 border-solid outline-none text-white " type="text" onChange={changeHandler} value={data.lName} name="lName" placeholder="Last Name" />
                </div>
              </div>

              <div className="flex mt-4">
                <div className=" mr-4 w-full lg:max-w-[50%]">
                  <p className="text-white mb-2">Email Address</p>

                  <input className="w-full lg:w-full py-3  px-3 rounded-[4px] border-gray-50 border-solid outline-none text-white  bg-[#4c4545]" type="text" onChange={changeHandler} value={data.email} name="email" placeholder=" Email Address" />
                </div>
                <div className=" w-full lg:max-w-[50%]">
                  <p className="text-white mb-2">Subject</p>

                  <input className=" w-full lg:w-full px-3 bg-[#4c4545] py-3 rounded-[4px] border-gray-50 border-solid outline-none text-white " type="text" onChange={changeHandler} value={data.subject} name="subject" placeholder="Subject" />
                </div>
              </div>
              <p className="text-white mt-4 mb-2">Message</p>
              <textarea rows="4" cols="50" placeholder="Your message here" onChange={changeHandler} value={data.message} name="message" className="w-full lg:w-full px-3 bg-[#4c4545] py-3 rounded-[4px] border-gray-50 border-solid outline-none text-white " />
              <button type="submit" className="bg-[#D5B661] py-3 rounded-md mt-4 text-white px-6 ">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Extra;
