import {
  CheckCircleIcon,

} from "@heroicons/react/24/outline";
import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
const Fifthsection = () => {
  return (
    <>
      <div>
        <div className="bg_law relative bg-no-repeat bg-cover w-[100%] py-20 ">
          <div className="relative flex flex-wrap justify-around  z-10 text-white xl:max-w-7xl w-full mx-auto ">
            {/* First Main Div */}
            <div className=" max-w-[600px] bg-[#2d2424]">
              <div className="flex py-10 px-9">
              <img className="w-16 h-16 " alt="calender" src= {require("../assets/cal.png")} />
              
                <div className="pl-6">
                  <h2 className=" text-xl lg:text-3xl font-bold">
                    9 Years Of Legal Experience
                  </h2>
                  <p className="text-sm pt-5">
                    Our team has studied a wide variety of legal specialties and{" "}
                    <br />
                    continues to expand our fields of service through our
                    continued <br /> education trainings.
                  </p>
                </div>
              </div>

              <div className="flex pt-0 py-10 px-9">
                
              <FontAwesomeIcon icon={solid('user') } className="w-12 h-12 text-[#D5B661]" />
                {/* <UserIcon className="w-12 h-12 text-[#D5B661] " /> */}
                <div className="pl-6">
                  <h2 className="text-xl lg:text-3xl font-bold">
                    Professional and Custom Legal <br /> Experience
                  </h2>
                  <p className="text-sm pt-5">
                    - Choose how to meet with your lawyer
                  </p>
                  <p className="text-sm">- Choose your communications</p>
                  <p className="text-sm">
                    - Make fully informed decisions on your case
                  </p>
                </div>
              </div>

              <div className="flex pt-0 py-10 px-9">
              <FontAwesomeIcon icon={solid('people-group') } className="w-12 h-12 text-[#D5B661]" />
                {/* <UserGroupIcon className="w-12 h-12 text-[#D5B661] " /> */}
                <div className="pl-6">
                  <h2 className="text-3xl font-bold">
                    Find the Answers You Need
                  </h2>
                  <p className="text-sm pt-5">
                    Our community expands throughout Florida. If we don’t
                    provide the <br />
                    services you need, we know an attorney that does.
                  </p>
                </div>
              </div>
              <div></div>
              <div></div>
            </div>
            {/* SecondDiv */}
            <div className="px-10 py-10">
              <p className="  text-[#D5B661] text-lg pb-5">WHY CHOICE US</p>
              <h1 className="text-[35px] font-bold py-3">
                Why Choose our Legal and <br />
                Lawyer Services
              </h1>
              <p>
                We are dedicated to proving ourselves to each and every client.
              </p>
              <div className="flex mt-7">
                <CheckCircleIcon className="w-6 h-6 mx-3  rounded-[50%] text-[#D5B661]" />
                <p> 9 Years Experience</p>
              </div>
              <div className="flex mt-5">
                <CheckCircleIcon className="w-6 h-6 mx-3  rounded-[50%] text-[#D5B661]" />
                <p> Have Professional Team</p>
              </div>
              <div className="flex mt-5">
                <CheckCircleIcon className="w-6 h-6 mx-3  rounded-[50%] text-[#D5B661]" />
                <p>Has Trusted More 100+ Clients</p>
              </div>
            </div>
          </div>
          <div className="absolute w-full h-full top-0 left-0 bg-black/[0.5] "></div>
        </div>
      </div>
    </>
  );
};

export default Fifthsection;
