import React from "react";

import { GiWallet } from "react-icons/gi";
import { BiCheckShield } from "react-icons/bi";
import { AiOutlineFileSearch } from "react-icons/ai";
import {BsBagCheck} from "react-icons/bs";


const Thirdsection = () => {
 
  return (
    <>
      <div id="Services">
        <div className="bg_gaval bg-no-repeat bg-cover w-[100%] relative py-20 text-white bg-center">
          <div className="relative z-10">
            <div className="flex xl:max-w-[1400px] md:w-[90%]   mx-auto justify-between ">
              <div>
                <div className="pl-7">
                  <p className="text-[#d5b661] mb-5 text-lg">WHAT WE DO</p>
                  <h1 className="text-4xl lg:text-5xl font-bold">
                    Our Legal & Lawyer Service <br /> Provided
                  </h1>
                </div>
              </div>
              <div className="lg:block hidden" >
              
                {/* <FaGavel className="w-12 h-12 text-[#d5b661]" /> */}
                <img className=" w-32  h-32 " src={require("../assets/gavel.png")} alt ="gaval"/>
              </div>
            </div>
            {/* Second Part */}
            <div className="flex flex-wrap  xl:max-w-[1400px] w-[95%]  mx-auto justify-around items-center mt-9 ">
              <div className="group  border-[0.2px] group  hover:bg-[#d5b661] border-white/[0.20] p-8 w-[450px] mr-4 mt-3 mb-5">
                {/* <FaRegHandshake className="w-12 h-12 text-[#d5b661] " /> */}
                <img className=" w-20  h-14 group-hover:hidden  block" src={require("../assets/group.png")} alt="group-person"  />
                <img className=" w-20 h-14 group-hover:block hidden " src={require("../assets/group-black.png")}alt="group-person"  />
                <h1 className="text-3xl font-bold pb-4">Personal Injury</h1>
                <p>
                  Car Collisions, Slip and Falls, and Other Personal <br />
                  Injuries
                </p>
              </div>
              <div className="border-[0.2px] border-white/[0.20] p-8 w-[450px] mr-4 mt-3 mb-5 bg-[#d5b661] ">
                {/* <FaRegHandshake className="w-12 h-12 text-[#2d2424] " /> */}
                <img className="w-20 h-14 " src= {require("../assets/kind-hand.png")} alt="Hand-shake" />
                <h1 className="text-3xl font-bold pb-4">Family Law</h1>
                <p>
                  Divorce, Paternity, Visitation and Child Support <br />
                  Changes
                </p>
              </div>
              <div className="border-[0.2px] group hover:bg-[#d5b661] border-white/[0.20] p-10 w-[450px] mr-4 mt-3 mb-5">
                <GiWallet className="w-14 h-14 group-hover:text-black text-[#d5b661] " />
                {/* <img className=" w-20  h-16 " src={require("../assets/colorrr.png")}  /> */}
                <h1 className="text-3xl font-bold pb-4">Probate</h1>
                <p>Settling Family Affairs, Distribution of Assets</p>
              </div>

              <div className="border-[0.2px] border-white/[0.20] p-8 w-[450px] mr-4 mt-3 mb-5 bg-[#d5b661]">
                <BiCheckShield className="w-16 h-16 text-[#2d2424] mb-5 " />

                <h1 className="text-3xl font-bold pb-4">Wills</h1>
                <p>Establish Your Peace of Mind</p>
              </div>
              <div className="border-[0.2px] group hover:bg-[#d5b661]  border-white/[0.20] p-10 w-[450px] mr-4 mt-3 mb-5">
              
                <BsBagCheck className="w-12 h-12 group-hover:text-black text-[#d5b661] my-2 "/>
                <h1 className="text-3xl font-bold pb-4">Guardianship</h1>
                <p>Protecting Children’s Rights and Helping Families</p>
              </div>
              <div className="border-[0.2px] border-white/[0.20] p-8 w-[450px] mr-4 mt-0 bg-[#d5b661]">
                <AiOutlineFileSearch className="w-16 h-16 text-[#2d2424] mb-5 " />

                <h1 className="text-3xl font-bold pb-4">Immigration</h1>
                <p>Citizenship and Permanent Residency</p>
              </div>

              <div className="border-[0.2px] border-white/[0.20] p-8 w-[450px] mr-4 mt-3 bg-[#d5b661]">
              <img className="w-20 h-14 " src= {require("../assets/kind-hand.png")} alt="Hand-Shake"/>
                <h1 className="text-3xl font-bold pb-4">
                  Power Of Attorney And <br /> Enhanced Life Estates
                </h1>
                <p>Planning for Life’s Emergencies</p>
              </div>
            </div>
          </div>
          <div className="brightness-50  bg-black/50 absolute h-full w-full top-0 left-0"></div>
        </div>
      </div>
    </>
  );
};

export default Thirdsection;
