import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import Header from "../header";
import Blogcoment from "./blogcoment";
import { Link } from "react-router-dom";

const Floridalawupdates = () => {
  return (
    <div>
      <div className="bg_law2 bg-no-repeat bg-cover w-[100%] h-[800px]  bg-center  ">
        <Header />
      </div>
      {/* Blog Section */}
      <div className="xl:max-w-[1400px] w-[90%] mx-auto bg-white  mt-[-10%] ">
        {/* Icons div */}
        <div className="flex  justify-center pt-24 pb-10 text-center">
          <FontAwesomeIcon
            icon={solid("calendar")}
            className="w-5 h-5 text-[#D5B661]"
          />
          <p className="px-2 text-[#D5B661]">August 19, 2022</p>
          <FontAwesomeIcon
            icon={regular("clock")}
            className="w-5 h-5 text-[#6E6E6E] pl-3"
          />
          <p className="text-[#6E6E6E] px-2">10:39 pm</p>
        </div>

        <div className="text-center">
          <h1 className="font-sans text-5xl tracking-wide font-bold leading-6 py-10">
            Florida Law Updates
          </h1>

          <hr className="w-72 mx-auto  bg-[#D5B661] h-3 my-6" />
        </div>
        {/* Paragraph content */}
        <div className="mx-auto max-w-[95%] text-[#6E6E6E] text-[16px]  ">
          <p className="py-4">
            Starting on May 1, 2021, Florida courts have adopted a new summary
            judgement standard. Summary Judgement is a motion made by any party
            in a lawsuit that attempts to effectively end the proceedings. It is
            made when there is no genuine issue of material fact, and the moving
            party believes they are entitled to judgement as a matter of law.
          </p>

          <p className="py-4">
            The old standard would deny a motion for summary judgement if there
            was the “slightest doubt” that a material issue remained. However,
            the new standard for summary judgement requires the evidence one the
            issue of fact be probative enough that a reasonable jury could find
            in favor of the non-moving party. The new standard is more rigorous
            and requires an issue of fact be significant for the non-moving
            party to survive summary judgement. Attorneys and parties to a
            lawsuit must be more diligent in presenting their issues and
            obtaining evidence to survive a motion for summary judgement.
          </p>
        </div>

        {/* Prev Next Section */}
        <div className="py-5 mx-auto max-w-[95%]  ">
          <div className="w-[50%] ml-auto flex justify-end border-l border-[#d4d4d4] ">
            <div className="text-[.8rem] tracking-wider text-[#D5B661] px-2">
              <p>
                 <Link to="/insidethefirm">
                 Next <br />
                  Inside the Firm
                 </Link>
                
            
              </p>
            </div>
            <FontAwesomeIcon
              icon={solid("chevron-right")}
              className="w-7 h-5 text-[#d4d4d4] py-3 "
            />
          </div>
          <Blogcoment />
        </div>

        {/* Comment Section */}
      </div>
    </div>
  );
};

export default Floridalawupdates;
