import React from "react";

import maleLawyer from "../assets/malelawyer.jpg";
import Profile from "../assets/profile.png";
import Businessimg from "../assets/business-lawyer.jpg"
import { Link, useLocation } from "react-router-dom";

const Sixthsection = () => {
  const { pathname } = useLocation();
  return (
   
    <div id="blog">
      <div>
        <div className="  xl:max-w-[1440px] w-[90%] mx-auto mt-20">
          {/* First Main Section */}
          <div className="flex justify-between  m-4">
            <div>
              <p className="text-[#D5B661] whitespace-pre text-lg py-5">
                BLOG CONTENT
              </p>
              <h1 className="font-bold text-2xl  lg:text-5xl">
                Reading Our News & Blog <br /> Content
              </h1>
            </div>
            <div className="py-7 lg:block  hidden">
            <img className="w-32 h-24 " src= {require("../assets/update-book.png")} alt="Book-img" />
            {/* <FontAwesomeIcon icon={solid('book-open')} className="w-20 h-20 text-[#D5B661]" /> */}
              {/* <SlBookOpen className="w-20 h-20 text-[#D5B661]" /> */}
            </div>
          </div>

          {/* Second Main Section */}
          <div className="flex flex-wrap justify-around m-4 pb-6">
            <div className="max-w-[670px] h-auto  border  sm:mt-10">
            
              <div className="relative">
              <img src={maleLawyer} alt="male-lawyer" />
              <button className="rounded-full absolute top-5 sm:text-sm text-[12px]   text-white bg-[#D5B661] px-4 py-1 sm:left-[75%]  hidden sm:block "> UNCATEGORIZED</button>
              <img className="absolute rounded-full top-[92%] left-[4%] w-16  " src={Profile} alt="Profile" />
              </div>
              <div className="mt-14 px-10">
                <p className=" text-[#D5B661] text-2xl font-bold py-5">
                  Inside the Firm
                </p>
                <p className="text-sm text-[#777]">
                  Attorney Michael Toole Jr. attended the University at Buffalo
                  obtaining a Bachelor’s degree in Legal Studies. While
                  attending, he was employed with the Federal District
                </p>
              <Link to="/insidethefirm">
              <button className=" text-[#D5B661] text-sm my-10" pathname = "/insidethefirm">
                  READ MORE 
                </button>
              </Link>
                
              </div>
              <div className="flex text-sm border  px-10 max-w-full text-[#777] py-3">
                <div className="mr-3">
                  <p>August 19, 2022 </p>
                </div>
                <p> • No Comments </p>
              </div>
            </div>

            <div className="max-w-[670px] h-auto  border  sm:mt-10">
            
            <div className="relative">
            <img src={Businessimg} alt="Business-Lawyer" />
            <button className="rounded-full absolute top-5 sm:text-sm text-[12px]   text-white bg-[#D5B661] px-4 py-1 sm:left-[75%]  hidden sm:block "> UNCATEGORIZED</button>
            <img className="absolute rounded-full top-[92%] left-[4%] w-16  " src={Profile}  alt="Profile"/>
            </div>
            <div className="mt-14 px-10">
              <p className=" text-[#D5B661] text-2xl font-bold py-5">
              Florida Law Updates
              </p>
              <p className="text-sm text-[#777]">
              Starting on May 1, 2021, Florida courts have adopted a new summary judgement standard.
               Summary Judgement is a motion made by any party in a
              </p>
             <Link to="/Floridalawupdates">
             <button className=" text-[#D5B661] text-sm my-10" pathname = "/Floridalawupdates">
                READ MORE 
              </button>
             </Link>
             
            </div>
            <div className="flex text-sm border  px-10 max-w-full text-[#777] py-3">
              <div className="mr-3">
                <p>August 19, 2022 </p>
              </div>
              <p> • No Comments </p>
            </div>
          </div>




            {/* <div className=" max-w-[600px] px-5 border sm:mt-10 ">
              <div className=" h-[500px] bg_law  relative bg-cover  ">
                <button className="absolute right-[5%] top-[10%] bg-[#D5B661] text-white  p-1 rounded-xl">
                  UNCATEGORIZED
                </button>
                <img
                  src={Profile}
                  className="absolute rounded-full top-[90%] left-[2%] w-20 "
                  alt="Profile"
                />
              </div>
              <div className="mt-14 px-10">
                <p className=" text-[#D5B661] text-2xl font-bold py-5">
                Florida Law Updates
                </p>
                <p className="text-sm text-[#777]">
                Starting on May 1, 2021,
                 Florida courts have adopted a new summary judgement standard.
                  Summary Judgement is a motion made by any party in a
                </p>

                <button className=" text-[#D5B661] text-sm my-10">
                  READ MORE 
                </button>
              </div>
              <div className="flex text-sm border px-10 text-[#777] py-3">
                <div className="mr-3">
                  <p>August 19, 2022 </p>
                </div>
                <p> • No Comments </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sixthsection;
